import { reduxStore } from '../store';
import { setUser } from '../store/ducks/user';
import UserService from './UserService';

const { store } = reduxStore;
const finalStepValue = 40;

export class ProfileProcessService {
    static getCurrentStep() {
        return this.isInProcess() ? store.getState().user.onboardingProgress || 11 : finalStepValue;
    }

    static finishProcess() {
        this.updateUserStep(finalStepValue);
    }

    static isInProcess() {
        return store.getState().user.onboardingProgress !== finalStepValue;
    }

    static initProgressBar() {
        if (store.getState().user.onboardingProgress === 0) {
            if (!store.getState().user.avatar) {
                this.updateUserStep(11);
            } else if (!store.getState().user.name) {
                this.updateUserStep(12);
            } else if (!store.getState().user.firstName) {
                this.updateUserStep(21);
            } else if (!store.getState().user.birthday) {
                this.updateUserStep(22);
            } else if (!store.getState().user.emailRetrievalConsent) {
                this.updateUserStep(31);
            } else {
                this.finishProcess();
            }
        }
    }

    static getStepForUpdate(step): Record<string, unknown> {
        let updatedStep = store.getState().user.onboardingProgress;
        if (store.getState().user.onboardingProgress < step) {
            updatedStep = step;
        }
        return { onboardingProgress: updatedStep };
    }

    static updateUserStep(step) {
        if (this.isInProcess()) {
            const { user } = store.getState();
            UserService.userUpdate({ onboardingProgress: step }).then(() =>
                store.dispatch(
                    setUser({
                        ...user,
                        ...{ onboardingProgress: step },
                    })
                )
            );
        }
    }
}
